import React from 'react';

export const COMPARISONS = [
  {
    name: 'ENHANCED',
    label: 'ENHANCED',
    image: 'progressive-lenses-1-pc.webp',
    distance: 3,
    intermediate: 2,
    near: 2,
    keyDifferences: [
      {
        name: 'Easy adaptation',
      },
      {
        name: 'Balanced viewing zones',
      },
    ],
    price: {
      value: 100,
      isPlus: true,
      suffix: '',
    },
    isShowRemark: true,
    remark: '',
  },
  {
    name: 'PRESTIGE',
    label: 'PRESTIGE',
    image: 'progressive-lenses-2-pc.webp',
    distance: 4,
    intermediate: 3,
    near: 3,
    keyDifferences: [
      {
        name: 'Easy adaptation',
      },
      {
        name: 'Smaller area of distortion at the edges of the lens',
      },
    ],
    price: {
      value: 200,
      isPlus: true,
      suffix: '',
    },
    isShowRemark: false,
    remark: '',
  },
  {
    name: 'PRESTIGE GOLD',
    label: 'PRESTIGE GOLD',
    image: 'progressive-lenses-3-pc.webp',
    distance: 4,
    intermediate: 4,
    near: 4,
    keyDifferences: [
      {
        name: 'Comfortable vision for any type of activity',
      },
      {
        name: 'Easy accessibility for intermediate zone allows for smooth transition',
      },
    ],
    price: {
      value: 400,
      isPlus: true,
      suffix: '',
    },
    isShowRemark: false,
    remark: '',
  },
  {
    name: 'PRESTIGE PLATINUM',
    label: 'PRESTIGE PLATINUM',
    image: 'progressive-lenses-4-pc.webp',
    distance: 5,
    intermediate: 5,
    near: 5,
    keyDifferences: [
      {
        name: 'Fully personalised based on individual facial anatomy',
      },
      {
        name: 'Widened intermediate viewing zone',
      },
      {
        name: 'Significantly improved near viewing zone',
      },
    ],
    price: {
      value: 600,
      isPlus: true,
      suffix: '',
    },
    isShowRemark: true,
    remark: '* Available at MBS and Takashimaya S.C. premium concept stores only',
  },
];
