export const LENS_CATEGORIES = [
  'ALL',
  'Fishing/Skiing',
  'Driving/Outdoor Activities',
  'over 40s',
  'Kids',
  'PC/Smartphone',
  'While wearing a face mask',
];

export const LENS_LIST = [
  {
    id: 'aspheric',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_1-lens.webp',
    tag: 'Standard',
    type: 'High Index Aspheric Lenses',
    price: '+ S$0',
    categories: [
      'Fishing/Skiing',
      'Driving/Outdoor Activities',
      'over 40s',
      'Kids',
      'PC/Smartphone',
      'While wearing a face mask',
    ],
  },
  {
    id: 'honeycomb',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_1-lens.webp',
    tag: 'Option',
    type: 'Honeycomb Lenses',
    typeAdditional: '(available with selected optional lens upgrades)',
    price: '+ S$0',
    categories: ['Driving/Outdoor Activities'],
  },
  {
    id: 'plus',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_1-lens.webp',
    tag: 'Option',
    type: 'OWNDAYS PLUS',
    typeAdditional: '(available at premium concept stores only)',
    price: '+ S$100',
    categories: ['PC/Smartphone'],
  },
  {
    id: 'progressive',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_2-lens.webp',
    tag: 'Option',
    type: 'Enhanced Progressive Lenses',
    price: '+ S$100',
    categories: ['over 40s'],
  },
  {
    id: 'prestige-progressive',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_2-lens.webp',
    tag: 'Option',
    type: 'Prestige Progressive',
    price: '+ S$200',
    categories: ['over 40s'],
  },
  {
    id: 'prestige-gold',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_2-lens.webp',
    tag: 'Option',
    type: 'Prestige Gold',
    price: '+ S$400',
    categories: ['over 40s'],
  },
  {
    id: 'prestige-platinum',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_2-lens.webp',
    tag: 'Option',
    type: 'Prestige Platinum',
    typeAdditional: '(available at premium concept stores only)',
    price: '+ S$600',
    categories: ['over 40s'],
  },
  {
    id: 'blue-light-lenses',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_5-lens.webp',
    tag: 'Option',
    type: 'Blue Light Lenses',
    price: '+ S$100',
    categories: ['PC/Smartphone'],
  },
  {
    id: 'pc420',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_5-lens.webp',
    tag: 'Option',
    type: 'PC420',
    price: '+ S$100',
    categories: ['PC/Smartphone'],
  },
  {
    id: 'transitions-light',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_4-lens.webp',
    tag: 'Option',
    type: '<img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-light_logo.webp" alt="Transitions">Light Intelligent Lenses',
    price: '+ S$200',
    categories: ['Driving/Outdoor Activities'],
  },
  {
    id: 'transitions-generation',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_4-lens.webp',
    tag: 'Option',
    type: '<img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-light_logo.webp" alt="Transitions">XTRActive® New Generation',
    price: '+ S$200',
    categories: ['Driving/Outdoor Activities'],
  },
  {
    id: 'transitions-polarized',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_4-lens.webp',
    tag: 'Option',
    type: '<img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-light_logo.webp" alt="Transitions">XTRActive® Polarized™',
    price: '+ S$300',
    categories: ['Driving/Outdoor Activities'],
  },
  {
    id: 'polarised',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_3-lens.webp',
    tag: 'Option',
    type: 'Polarised Lenses',
    price: '+ S$100',
    categories: ['Driving/Outdoor Activities', 'Fishing/Skiing'],
  },
  {
    id: 'color',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_6-lens.webp',
    tag: 'Option',
    type: 'Colour Lenses',
    price: '+ S$100',
    categories: ['Driving/Outdoor Activities', 'Fishing/Skiing'],
  },
  {
    id: 'myokid',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_1-lens.webp',
    tag: 'Option',
    type: 'Myopia Control 2',
    price: '+ S$100',
    categories: ['Kids'],
  },
  {
    id: 'antifog',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_7-lens.webp',
    tag: 'Option',
    type: 'Anti-Fog Lenses',
    price: '+ S$100',
    categories: ['While wearing a face mask'],
  },
];

export const LENS_DETAILS = [
  {
    id: 'aspheric',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_1-lens.webp',
    tag: 'Standard',
    type: 'High Index Aspheric Lenses',
    price: '+ S$0',
    description: 'High quality lenses that are thinner and cause less visual distortion.',
    details:
      'Aspheric lenses cause less visual distortion compared to spherical lenses. They are also thinner and therefore lighter. In OWNDAYS, you can enjoy high index aspheric lenses at no additional fee.',
    features: [
      'High Index Aspheric Lenses',
      '99% UV Protection',
      'Anti-Reflection',
      'Scratch Resistant',
      'Dust-Repellent',
    ],
    refractiveIndex: '1.60, 1.67, 1.74',
    examples: `<ul class="cols cols-pc-2 cols-pc-bd cols-pc-aspheric">
      <li>
        <h6>Difference in vision field between spherical and aspheric lenses</h6>
        <div class="cols cols-2">
          <figure>
            <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/aspheric-01.webp" />
            <figcaption>Spherical Lenses</figcaption>
          </figure>
          <figure>
            <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/aspheric-02.webp" />
            <figcaption>Aspheric Lenses</figcaption>
          </figure>
        </div>
      </li>
      <li>
        <h6>Difference in lens thickness and refractive index</h6>
        <div>
          <table>
            <tr>
              <td></td>
              <td>Refractive Index</td>
              <td></td>
            </tr>
            <tr>
              <td><span style="display: inline-block; padding: 0 2px; background-color: #999; color: #fff;">Thick</span></td>
              <td style="padding: 0 4px;">
                <figure>
                  <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/aspheric-03.webp" />
                </figure>
              </td>
              <td><span style="display: inline-block; padding: 0 2px; background-color: #e3e3e3; color: #999;">Thin</span></td>
            </tr>
            <tr>
              <td></td>
              <td>The higher the refractive index, the thinner the lens and the lesser the visual distortion</td>
              <td></td>
            </tr>
          </table>
        </div>
      </li>
    </ul>`,
  },
  {
    id: 'honeycomb',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_1-lens.webp',
    tag: 'Option',
    type: 'Honeycomb Lenses',
    typeAdditional: '(available with selected optional lens upgrades)',
    price: '+ S$0',
    description: 'Designed to provide sharper vision especially at night.',
    details:
      'Created using the latest nanotechnology, honeycomb lenses allow you to enjoy crystal-clear vision especially during the night or in low light conditions. The lenses come with a unique coating with a honeycomb pattern that provides a pinhole effect, reducing scattered light to give you sharper, more focused vision. Other benefits of honeycomb lenses include protecting your eyes from UV, lessening eye fatigue and reducing glare. <br/>Available for complimentary upgrade* with purchase of PC420, progressive and selected Transitions® lenses. <br/><small>T&Cs apply.</small>',
    features: [
      'High Index Aspheric Lenses',
      '99% UV Protection',
      'Anti-Reflection',
      'Scratch Resistant',
      'Dust-Repellent',
    ],
    refractiveIndex: '1.60, 1.67, 1.74',
    examples: `<ul>
      <li>
        <h6>Comparison between Regular Lens and Honeycomb Lens</h6>
        <ul class="cols cols-pc-2 cols-pc-bd">
          <li>
            <h6>Regular Lens</h6>
            <div>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/honeycomb-01.webp" />
                <figcaption class="figcaption-left">All light rays can pass through the lens into the eye.</figcaption>
              </figure>
            </div>
          </li>
          <li>
            <h6>Honeycomb Lens</h6>
            <div>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/honeycomb-02.webp" />
                <figcaption class="figcaption-left">The honeycomb pattern removes scattered light rays so only parallel lights enter the eye, thus improving clarity of the visual field.</figcaption>
              </figure>
            </div>
          </li>
        </ul>
      </li>
    </ul>`,
  },
  {
    id: 'plus',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_1-lens.webp',
    tag: 'Option',
    type: 'OWNDAYS PLUS',
    typeAdditional: '(available at MBS and Takashimaya S.C. premium concept stores only)',
    price: '+ S$100',
    description:
      'An aspheric lens with premium coating available exclusively at premium concept stores',
    details:
      'OWNDAYS PLUS lenses can significantly block harmful UV rays from the reverse side offering you full protection. With an anti-reflective coating, the lenses offer increased eye comfort without the added eye strain caused by excessive light. The anti-static coating allows the lenses to remain aesthetically clean with less dust attracted on the lens surface. Smudges, water, fingerprints and dust on the lenses can be removed easily, making cleaning and maintenance a breeze.',
    features: ['High Index Aspheric Lenses', '99% UV Protection'],
    refractiveIndex: '1.60, 1.67',
    examples: `<ul>
      <li>
        <h6>Comparison between standard aspheric lenses and OWNDAYS PLUS lenses</h6>
        <div style="max-width: 420px; margin: 0 auto;">
          <figure>
            <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/graph.webp" />
          </figure>
        </div>
      </li>
    </ul>`,
  },
  {
    id: 'progressive',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_2-lens.webp',
    tag: 'Option',
    type: 'Enhanced Progressive Lenses',
    price: '+ S$100',
    description:
      'Offer a wider field of vision by providing vision support for near and far zones.',
    details:
      'Progressive lenses have no "boundary line" and allow you to use a single pair of glasses to view objects at all distances. They are currently the preferred type of lenses for presbyopia.',
    lineup: [
      {
        id: 'enhanced-progressive',
        type: 'Enhanced Progressive Lenses ',
        price: '+ S$100',
        details:
          'With Progressive (for far & near viewing distances) and Computer Progressive (for near & near viewing distances), you can now enjoy the most comfortable vision by choosing the type of progressive lenses most catered to your needs. <br/>Also available in Transitions®, PC and other colour options at a top up fee of S$100 per option.',
        features: [
          'High Index Aspheric Lenses',
          '99% UV Protection',
          'Anti-Reflection',
          'Scratch Resistant',
          'Dust-Repellent',
        ],
        refractiveIndex: '1.60, 1.67',
        recommendedFor:
          'For people aged 40 years and above and those who have trouble seeing near objects clearly',
        examples: `<ul>
          <li>
            <h6>Differences between progressive lenses catered to difference viewing distances</h6>
            <div>
              <p>Image is for illustration purposes only.</p>
              <ul class="cols cols-pc-2 cols-pc-bd">
                <li>
                  <figure>
                    <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/progressive-01.webp" />
                    <figcaption>Progressive Lenses</figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/progressive-03.webp" />
                    <figcaption>Computer Lenses</figcaption>
                  </figure>
                </li>
              </ul>
            </div>
          </li>
        </ul>`,
      },
      {
        id: 'prestige-progressive',
        type: 'Prestige Progressive',
        price: '+ S$200',
        details:
          'A premium progressive lens that provides optimised vision catered to individual needs. By taking into account the wearer‘s main daily activities, the lens can significantly improve vision clarity, sharpness and comfort in all vision zones.<br/>Also available in transitions®, PC and other colour options at a top up fee of S$100 per option.',
        features: [
          'High Index Aspheric Lenses',
          '99% UV Protection',
          'Anti-Reflection',
          'Scratch Resistant',
          'Dust-Repellent',
        ],
        refractiveIndex: '1.60, 1.67 <br/>^Upgrade to 1.74 lenses at additional S$100',
        recommendedFor:
          'People aged 40 years and above and those who have trouble seeing near objects clearly',
      },
      {
        id: 'prestige-gold',
        type: 'Prestige Gold',
        price: '+ S$400',
        details:
          'A premium progressive lens that offers seamless transition between viewing fields. The intermediate vision zone is 50% wider than that of Prestige Progressive, thus allowing for easier adaption. With Prestige Gold, revel in maximum visual comfort with significantly improved vision clarity, sharpness and comfort in all vision zones.',
        features: [
          'High Index Aspheric Lenses',
          '99% UV Protection',
          'Anti-Reflection',
          'Scratch Resistant',
          'Dust-Repellent',
        ],
        refractiveIndex: '1.60, 1.67 <br/>^Upgrade to 1.74 lenses at additional S$100',
        recommendedFor:
          'People aged 40 years and above and those who have trouble seeing near objects clearly',
        examples: `<ul>
            <li>
              <h6>Difference in vision field between regular and Prestige Progressive lenses</h6>
              <div>
                <p>Image is for illustration purposes only.</p>
                <ul class="cols cols-pc-2 cols-pc-bd">
                  <li>
                    <figure>
                      <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/progressive-01.webp" />
                      <figcaption>Progressive Lenses</figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/progressive-04.webp" />
                      <figcaption>Prestige Lenses</figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
            </li>
          </ul>`,
      },
      {
        id: 'prestige-platinum',
        type: 'Prestige Platinum',
        typeAdditional: '(available at MBS and Takashimaya S.C. premium concept stores only)',
        price: '+ S$600',
        details:
          'A personalised premium progressive lens that is positioned based on individual facial anatomy to optimise visual comfort and performance in every wearing condition. It provides widened intermediate vision and significantly improved near vision with customized near vision zone position and less visual distortion.',
        features: [
          'High Index Aspheric Lenses',
          '99% UV Protection',
          'Anti-Reflection',
          'Scratch Resistant',
          'Dust-Repellent',
        ],
        refractiveIndex: '1.60, 1.67 <br/>^Upgrade to 1.74 lenses at additional S$100',
        recommendedFor:
          'People aged 40 years and above and those who have trouble seeing near objects clearly',
        examples: `<ul>
          <li>
            <h6>Prestige Platinum is customised using these parameters based on an individual’s facial anatomy</h6>
            <div>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/platinum.webp" />
              </figure>
            </div>
          </li>
        </ul>`,
      },
    ],
  },
  {
    id: 'blue-light-lenses',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_5-lens.webp',
    tag: 'Option',
    type: 'Blue Light Lenses',
    price: '+ S$100',
    description:
      'Eyeshield + S$100<br/>Protect your eyes from blue light emitted by computer and smartphone screens.',
    details:
      'An advanced blue light lens which defends against blue light and glare all day long. Eyeshield lenses filter up to 40% of harmful blue-violet light from electronic devices and the environment. The lenses also come with an anti-reflection coating that reduces glare which can disturb the vision, especially at night.',
    features: [
      'High Index Aspheric Lenses',
      '99% UV Protection',
      'Scratch Resistant',
      'Dust-Repellent',
    ],
    refractiveIndex: '1.60, 1.67',
    recommendedFor:
      'While using PC/smartphone and when commuting regularly at night or in low light conditions',
    examples: `<ul>
      <li>
        <h6>Blue Light Reduction!</h6>
        <div>
          <figure>
            <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/pc-01.webp"/>
            <figcaption class="figcaption-pclens">
              <span>Blue Light-emitting digital device</span>
              <span>Light with reduced Blue Light enters the eye</span>
            </figcaption>
          </figure>
        </div>
      </li>
    </ul>`,
    lineup: [
      {
        id: 'pc420',
        type: 'PC420',
        price: '+ S$100',
        details:
          'Developed using the latest technologies, PC420 lenses are able to block up to 40% of blue light (380nm – 500nm), thereby preventing eye strain and headaches.',
        features: [
          'High Index Aspheric Lenses',
          '99% UV Protection',
          'Scratch Resistant',
          'Dust-Repellent',
        ],
        refractiveIndex: '1.60, 1.67, 1.74',
        recommendedFor: 'While using PC/smartphone',
      },
    ],
  },
  {
    id: 'transitions-light',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_4-lens.webp',
    tag: 'Option',
    type: '<img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-light_logo.webp" alt="Transitions">Light Intelligent Lenses',
    price: '+ S$200',
    description:
      'Transitions® Light Intelligent Lenses™ automatically adapt to changing light conditions.',
    details: `<img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SG_TRMP_MINORU_GREY_DISPLAY_CUSTOM.webp"><br/><br/>
    <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-signature_logo.webp" alt="Transitions Signature" style="max-width: 120px;">
    <h5>PROTECT YOUR EYES IN STYLE</h5>
    <ul class="list-disc">
        <li>Offering a new frontier of performance returns to clear faster than ever and even darker
            outdoors</li>
        <li>The best protection for your eyes against UV light and harmful blue light</li>
        <li>Adapts seamlessly to any lighting letting you enjoy a hassle-free life</li>
        <li>Available in 3 iconic colours and 4 vibrant Style Colours</li>
    </ul>`,
    features: [
      'High Index Aspheric Lenses',
      '99% UV Protection',
      'Anti-Reflection',
      'Scratch Resistant',
      'Dust-Repellent',
    ],
    refractiveIndex: '1.60, 1.67',
    recommendedFor: 'Driving / Outdoor Activities',
    notes:
      'Transitions is a registered trademark of Transitions Optical, Inc. used under license by Transitions Optical Limited.',
  },
  {
    id: 'transitions-generation',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_4-lens.webp',
    tag: 'Option',
    type: '<img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-light_logo.webp" alt="Transitions">XTRActive® New Generation',
    price: '+ S$200',
    description: 'Protect your eyes from bright sunlight while driving.',
    details:
      'Designed for people who are sensitive to light, Transitions® XTRActive® New Generation lenses offer light protection both indoors and outdoors. With new powerful XTRActive® dyes, they are able to absorb visible light, thereby activating the lenses inside the car as well as causing them to turn darker outdoors.<br/>Transitions® XTRActive® New Generation lenses are available in 3 iconic colours - grey, graphite green and brown.',
    features: [
      'High Index Aspheric Lenses',
      '99% UV Protection',
      'Anti-Reflection',
      'Scratch Resistant',
      'Dust-Repellent',
    ],
    refractiveIndex: '1.60, 1.67',
    recommendedFor:
      'Driving / Outdoor Activities / People who are very light-sensitive or are frequently exposed to intense light',
    examples: ``,
  },
  {
    id: 'transitions-polarized',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_4-lens.webp',
    tag: 'Option',
    type: '<img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-light_logo.webp" alt="Transitions">XTRActive® Polarized™',
    price: '+ S$300',
    description: 'High quality lenses that are thinner and cause less visual distortion.',
    details:
      'Aspheric lenses cause less visual distortion compared to spherical lenses. They are also thinner and therefore lighter. In OWNDAYS, you can enjoy high index aspheric lenses at no additional fee.',
    features: [
      'High Index Aspheric Lenses',
      '99% UV Protection',
      'Anti-Reflection',
      'Scratch Resistant',
      'Dust-Repellent',
    ],
    refractiveIndex: '1.60, 1.67',
    recommendedFor:
      'Driving / Outdoor Activities / People who are very light-sensitive or are frequently exposed to intense light',
    examples: `<ul>
      <li>
        <h6>Colour Options</h6>
        <div>
          <ul class="cols cols-lens-transition">
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-grey.webp"/>
                <figcaption>Grey</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-graphite-green.webp"/>
                <figcaption>Graphite Green</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/transitions-brown.webp"/>
                <figcaption>Brown</figcaption>
              </figure>
            </li>
          </ul>
        </div>
      </li>
    </ul>`,
    notes:
      'Transitions is a registered trademark of Transitions Optical, Inc. used under license by Transitions Optical Limited.',
  },
  {
    id: 'polarised',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_3-lens.webp',
    tag: 'Option',
    type: 'Polarised Lenses',
    price: '+ S$100',
    description: 'Reduce glare from shiny surfaces; suitable for outdoor use.',
    details:
      'Polarised lenses reduce the glare from shiny surfaces such as the road, snow and water surface to give you a more comfortable vision while helping you see better. They are different from sunglasses which are essentially colour lenses that make your vision darker. In each polarised lens, a filter is inserted between 2 pieces of lenses to cut down UV light and reflected light such that only natural light could pass through.<br/>Polarised lenses are ideal for outdoor activities such as fishing, golf, skiing and driving.',
    features: [
      'Spherical Lenses',
      '99% UV Protection',
      'Anti-Reflection',
      'Scratch Resistant',
      'Dust-Repellent',
    ],
    refractiveIndex: '1.60',
    recommendedFor: 'Fishing / Skiing / Driving / Outdoor Activities',
    examples: `<ul class="cols cols-pc-2 cols-pc-bd">
      <li>
        <h6>Lens composition</h6>
        <div>
          <figure>
            <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/polarised-01.webp"/>
            <figcaption>Polarised Coating</figcaption>
          </figure>
        </div>
      </li>
      <li style="display: flex; flex-direction: column;">
        <h6>Color Variation</h6>
        <div style="
            display: flex;
            flex-direction: column;
            height: 100%;
        ">
          <ul class="cols cols-lens-color" style="
              height: 100%;
              place-content: center;
          ">
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/polarised-02.webp"/>
                <figcaption>Smoky Grey</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/polarised-03.webp"/>
                <figcaption>Brown</figcaption>
              </figure>
            </li>
          </ul>
          <p class="services__lens-details-body-notes" style="margin-bottom: 0;">Reducing unnecessary light such as reflected light and UV light so as to produce clearer vision.</p>
        </div>
      </li>
    </ul>`,
  },
  {
    id: 'color',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_6-lens.webp',
    tag: 'Option',
    type: 'Colour Lenses',
    price: '+ S$100',
    description: 'Convert your favourite frames into trendy sunglasses.',
    details:
      'OWNDAYS provides a variety of colour options. Just pick any pair of frame and convert it into a pair of sunglasses according to your liking and style.',
    features: [
      'High Index Aspheric Lenses',
      '99% UV Protection',
      'Anti-Reflection',
      'Scratch Resistant',
      'Dust-Repellent',
    ],
    refractiveIndex: '1.60, 1.67, 1.74',
    recommendedFor: 'Fishing / Skiing / Driving / Outdoor Activities',
    examples: `<ul>
      <li>
        <h6>Color Variation</h6>
        <div>
          <ul class="cols cols-lens-color" style="max-width: 700px;">
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SDBR85.webp" />
                <figcaption>SDBR85</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SKGY85.webp"/>
                <figcaption>SKGY85</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/UBGN85.webp"/>
                <figcaption>UBGN85</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SDGY85.webp"/>
                <figcaption>SDGY85</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SDBR62.webp"/>
                <figcaption>SDBR62</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SKGY62.webp"/>
                <figcaption>SKGY62</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/UBGN62.webp"/>
                <figcaption>UBGN62</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SDGY62.webp"/>
                <figcaption>SDGY62</figcaption>
              </figure>
            </li>

            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SDBR85G.webp"/>
                <figcaption>SDBR85G</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SKGY85G.webp"/>
                <figcaption>SKGY85G</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/UBGN85G.webp"/>
                <figcaption>UBGN85G</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SDGY85G.webp"/>
                <figcaption>SDGY85G</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SDBR35G.webp"/>
                <figcaption>SDBR35G</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SKGY35G.webp"/>
                <figcaption>SKGY35G</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/UBGN35G.webp"/>
                <figcaption>UBGN35G</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/SDGY35G.webp"/>
                <figcaption>SDGY35G</figcaption>
              </figure>
            </li>
          </ul>
        </div>
      </li>
    </ul>`,
  },
  {
    id: 'myokid',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_1-lens.webp',
    tag: 'Option',
    type: 'Myopia Control 2',
    price: '+ S$100',
    description: 'Slow down myopia progression for children.',
    details: `Myopia Control 2 lens is clinically proven to slow down myopia progression for children by up to 50%<sup>1</sup>. It is designed to reduce peripheral hyperopic defocus to minimise eye growth signal which potentially controls the axial elongation of the eyeball.<br/>
      <br/>
      We offer an all-round myopia management programme, consisting of the following components:
      <ul class="list-disc">
        <li>Monitoring of the child's myopia progression through a complimentary review every 6 months</li>
        <li>Complimentary<sup>2</sup> change of Myopia Control 2 lens degree within the 1st 6-month of purchase in the event myopia is increased by 0.50 Diopter</li>
        <li>Consultation on alternative ways of managing myopia progression, such as Ortho-K lenses, in the event Myopia Control 2 has not achieved the intended results discovered through the complimentary reviews</li>
      </ul>
      <br/>
      <ul class="list-notes">
        <li><sup>1</sup> Children wearing myopic peripheral defocus lenses (MPDL) showed reductions in
            absolute growth of axial length by 50% and 39% after 6 and 12 months of treatment
            respectively. Sánchez-Tena MÁ, Cleva JM, Villa-Collar C, et al. Effectiveness of a Spectacle
            Lens with a Specific Asymmetric Myopic Peripheral Defocus: 12-Month Results in a Spanish
            Population. Children. 2024; 11(2):177.<br/>
            <a href="https://doi.org/10.3390/children11020177">https://doi.org/10.3390/children11020177</a>
        </li>
        <li><sup>2</sup> Limited to 1-time change, other T&amp;Cs apply</li>
      </ul>
    `,
    features: [
      'High Index Aspheric Lenses',
      '99% UV Protection',
      'Anti-Reflection',
      'Scratch Resistant',
      'Dust-Repellent',
    ],
    refractiveIndex: '1.60',
    recommendedFor: 'Children aged between 6 to 16 years',
    examples: `<ul>
      <li>
        <div>
          <figure>
            <img src="https://static.lenskart.com/media/owndays/desktop/img/myopia-control-2.webp"/>
          </figure>
        </div>
      </li>
    </ul>`,
  },
  {
    id: 'antifog',
    imgPath: 'https://static.lenskart.com/media/owndays/img/services-lens/detail_7-lens.webp',
    tag: 'Option',
    type: 'Anti-Fog Lenses',
    price: '+ S$100',
    description: 'Keep your spectacles from fogging up.',
    details:
      'A temperature difference between the lens surface and the surrounding air causes condensation on the lens surface, making your spectacles foggy. To prevent the build-up of condensation, there is a special hydrophilic coating on an anti-fog lens that spreads water droplets evenly across the lens surface to form a thin film of water. This allows the anti-fog lens to remain clear at all times.',
    features: ['High Index Aspheric Lenses', '99% UV Protection'],
    refractiveIndex: '1.60, 1.67',
    recommendedFor: 'While consuming hot food and beverages / wearing face mask / cycling',
    examples: `<ul style="max-width: 411px;">
      <li>
        <div>
          <figure>
            <img src="https://static.lenskart.com/media/owndays/desktop/img/services-lens/antifog-01.webp"/>
          </figure>
        </div>
      </li>
    </ul>`,
    notes: 'Applicable only for single vision lenses',
  },
];
