import React from 'react';
import { connect } from 'react-redux';
import './Breadcrumb.scss';
import { v4 } from 'uuid';

const Breadcrumb = props => {
  const { breadcrumbData, contactLensPowerFromUrl, productID, hideFirstCrumb = false } = props;

  const getEnvUrl = () => {};

  return (
    <div className="breadcrumbContainer">
      <div className="breadcrumbContainer_wrapper">
        <ul className="breadcrumbContainer_wrapper_breadcrumb">
          {breadcrumbData &&
            breadcrumbData.map((item, index) => {
              if (index === 0 && hideFirstCrumb) return null;
              return (
                <li key={v4()}>
                  {item.link && item.label !== 'Mobile' && (
                    <span className="od-font-reg">
                      <a
                        href={
                          item.link?.indexOf('www.owndays.com' || 'preproduction.owndays.com') ===
                          -1
                            ? `/${item.link}`
                            : '/'
                        }
                      >
                        {item.label}
                      </a>
                    </span>
                  )}
                  {!item.link && item.label !== 'Mobile' && (
                    <span className="od-font-bold">
                      {item.label} {productID && `- ${productID}`}
                    </span>
                  )}
                </li>
              );
            })}
          {contactLensPowerFromUrl && <li>{contactLensPowerFromUrl}</li>}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
  };
};

export default connect(mapStateToProps, null)(Breadcrumb);
