export const IMG_URL = 'https://static1.lenskart.com/media/owndays/img/contacts/color-contactlens';
export const LINEUP = [
  {
    imgSrc: 'package-natural.webp',
    imgAlt: 'NATURAL SERIES',
    imgH: 92,
    tags: ['10 lenses per box', 'Daily'],
    title: 'NATURAL SERIES',
    price: 'S$18',
    href: '/sg/en/contacts/color-contactlens/natural',
  },
  {
    imgSrc: 'package-horoscope.webp',
    imgAlt: 'HOROSCOPE SERIES',
    imgH: 92,
    tags: ['10 lenses per box', 'Monthly'],
    title: 'HOROSCOPE SERIES',
    price: 'S$18',
    href: '/sg/en/contacts/color-contactlens/horoscope',
  },
  {
    imgSrc: 'package-petal.webp',
    imgAlt: 'PETAL SERIES',
    imgH: 235,
    tags: ['2 lenses per box', 'Monthly'],
    title: 'PETAL SERIES',
    price: 'S$15',
    href: '/sg/en/contacts/color-contactlens/petal',
  },
  {
    imgSrc: 'package-velvet.webp',
    imgAlt: 'VELVET SERIES',
    imgH: 235,
    tags: ['2 lenses per box', 'Monthly'],
    title: 'VELVET SERIES',
    price: 'S$15',
    href: '/sg/en/contacts/color-contactlens/velvet',
  }
];
