import React, { useState } from 'react';

import './FormPolicy.scss';

const OPTIONS = [
  { name: 'Email', isChecked: true, key: 'email' },
  { name: 'Text Message', isChecked: true, key: 'sms' },
];

export const FormPolicy = () => {
  const [listReceiveNews, setListReceiveNews] = useState(OPTIONS);
  const [isAgree, setIsAgree] = useState(false);

  const toggleCheckboxChange = selectedItem => {
    setListReceiveNews(
      listReceiveNews.map(item =>
        item.name === selectedItem.name ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  const handleRadioChange = () => {
    setIsAgree(prevState => !prevState);
  };

  return (
    <div className="form-policy__container">
      <div className="form-policy__box">
        <p className="form-policy__question">
          I consent that you use my personal information for the purposes mentioned above.
        </p>

        <div className="form-policy__agree-layout">
          <input
            checked={isAgree}
            className="form-policy__radio"
            type="radio"
            value="Agree"
            onClick={handleRadioChange}
          />
          <label className="form-policy__name" htmlFor="Agree">
            Agree
          </label>
        </div>
      </div>

      <div className="form-policy__box">
        <p className="form-policy__question">
          How would you like to receive new and special offers from us ?
        </p>

        <ul className="form-policy__list">
          {listReceiveNews.map(item => (
            <li key={`form-policy-option-${item.name.toLowerCase()}`} className="form-policy__item">
              <input
                checked={item.isChecked}
                className="form-policy__checkbox"
                name={item.name}
                type="checkbox"
                value={item.name}
                onChange={() => toggleCheckboxChange(item)}
              />
              <label className="form-policy__name" htmlFor={item.name}>
                {item.name}
              </label>
            </li>
          ))}
        </ul>
      </div>

      <a>
        <div
          className={`form-policy__confirm-button ${
            isAgree
              ? 'form-policy__confirm-button--active'
              : 'form-policy__confirm-button--inactive'
          }`}
        >
          Confirm
        </div>
      </a>
    </div>
  );
};
