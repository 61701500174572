import React, { useState, useEffect } from 'react';
import './LoginSignup.scss';
import { registerUser } from '../../../actionCreators/auth';
import { validateSingaporeMobileNumber } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { setToastMessage } from '../../../actionCreators/common';
import { parseQueryParams } from '../../../own-days/helper';
import { odPushClevertapEvent } from '../../../utils/clevertap';
import ReceiveNews from '../../../CommonComponents/ReceiveNews/ReceiveNews';
import TermsAndConditionsText from '../../../CommonComponents/TermsAndConditionsText/TermsAndConditionsText';

const Signup = props => {
  const { history } = props;
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState('male');
  const [refCode, setRefCode] = useState('');
  const [showRefCode, setShowRefCode] = useState(false);
  const [phoneErr, setPhoneErr] = useState('');
  const [nameErr, setNameErr] = useState('');
  const [whatsappInfo, setWhatsappInfo] = useState(false);
  const [visitReason, setVisitReason] = useState('buyGlasses');
  const [receiveNews, setReceiveNews] = useState({
    email: false,
    sms: false,
  });

  const isLogin = useSelector(state => state.common?.login);
  const registerError = useSelector(state => state?.auth?.registerError?.message);
  const authLoading = useSelector(state => state?.auth?.loading);
  const { storeCode } = parseQueryParams(['storeCode']);
  const { whatsappId, mobileNo, staffCode } = parseQueryParams([
    'whatsappId',
    'mobileNo',
    'staffCode',
    'storeCode',
  ]);

  const dispatch = useDispatch();
  const prevUrl = props?.location?.state?.prevUrl;

  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'Signup Page',
      pageUrl: window.location.href,
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const nameArr = fullName?.trim()?.split(' ');
    const firstName = nameArr[0];
    const lastName = nameArr[nameArr.length - 1];

    if (firstName.length < 1 || lastName.length < 1) {
      if (firstName.length === 0) {
        setNameErr('Firstname is required');
      }
      if (lastName.length === 0) {
        setNameErr('Lastname is required');
      }
      if (nameArr.length < 2) {
        setNameErr('Firstname and Lastname is required');
      }
      return;
    }
    setNameErr('');

    if (!validateSingaporeMobileNumber(phoneNumber)) {
      setPhoneErr('Please enter a valid singapore mobile number');
      return;
    }
    setPhoneErr('');

    const data = {
      dob,
      firstName,
      lastName,
      mobile: phoneNumber,
      email,
      password,
      referCode: refCode,
      gender,
      phoneCode: '+65',
      visitReason,
      ...whatsappInfo,
      marketingSubscription: {
        ...receiveNews,
      },
    };

    console.log('form dataa ===>', data);
    dispatch(registerUser(data));
  };

  useEffect(() => {
    console.log('@SINGUP LOGIC ====>', isLogin, prevUrl);
  }, [prevUrl]);

  useEffect(() => {
    dispatch(setToastMessage({ warning: true, message: registerError, timeout: 3000 }));
  }, [registerError]);

  useEffect(() => {
    if (isLogin) {
      const redirectTo = prevUrl ? prevUrl.replace(/\/sg\/en/g, '') : '';
      if (redirectTo?.length > 0 || redirectTo === '') {
        window.location.href = redirectTo === '/' ? redirectTo : `/sg/en${redirectTo}`;
        // history.push(redirectTo);
      } else {
        window.location.href = '/';
      }
    }
  }, [isLogin]);

  useEffect(() => {
    const { whatsappId, mobileNo, staffCode, storeCode } = parseQueryParams([
      'whatsappId',
      'mobileNo',
      'staffCode',
      'storeCode',
    ]);

    if (mobileNo && whatsappId) {
      setWhatsappInfo(prevState => {
        return { ...prevState, mobileNo, whatsappId };
      });
    }

    if (staffCode && storeCode) {
      setWhatsappInfo(prevState => {
        return { ...prevState, staffCode, storeCode };
      });
    }
  }, []);

  useEffect(() => {
    setPhoneNumber(whatsappInfo?.mobileNo || '');
  }, [whatsappInfo]);

  return (
    <div className="od-login-page">
      <div className="od-login-page-left" />
      <div className="od-login-page-right">
        <div className="od-login-page-right-flex">
          <h3 className="od-login-page-right-h1">
            Please sign in or create an account to complete checkout
          </h3>
          <div className="od-login-page-right-form">
            <h4>Sign up</h4>
            <form onSubmit={handleSubmit}>
              <input
                required
                placeholder="Full name"
                type="text"
                value={fullName}
                onChange={e => setFullName(e.target.value)}
              />
              {nameErr && <div className="od-login-page-input-error">{nameErr}</div>}
              <div className="od-login-page-number-wrapper">
                <input
                  disabled
                  className={`od-login-page-number-wrapper_code ${
                    phoneNumber &&
                    whatsappInfo?.mobileNo &&
                    'od-login-page-number-wrapper_code--disabled'
                  }`}
                  placeholder="+65"
                />
                <input
                  required
                  className={`od-login-page-number-wrapper_number ${
                    phoneNumber &&
                    whatsappInfo?.mobileNo &&
                    'od-login-page-number-wrapper_number--disabled'
                  }`}
                  placeholder="Phone no"
                  type="number"
                  value={phoneNumber}
                  onChange={e => {
                    const phoneValue = e.target.value;
                    setPhoneNumber(phoneValue.slice(0, 8));
                  }}
                />
              </div>
              {phoneErr && <div className="od-login-page-input-error">{phoneErr}</div>}
              <input
                required
                placeholder="Email id"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <input
                required
                placeholder="Date of birth (dd/mm/yyyy)"
                type="date"
                value={dob}
                onChange={e => setDob(e.target.value)}
              />
              <input
                required
                placeholder="Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <select
                id="gender"
                name="gender"
                value={gender}
                onChange={e => setGender(e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              {storeCode && (
                <select
                  id="visitPurpose"
                  name="genvisitPurposeder"
                  value={visitReason}
                  onChange={e => setVisitReason(e.target.value)}
                >
                  <option value="buyGlasses">Glasses</option>
                  <option value="contactLens">Contact Lens</option>
                  <option value="lensReplacement">Lens Replacement</option>
                  <option value="afterCare">Warranty</option>
                </select>
              )}
              {showRefCode && (
                <input
                  placeholder="Referral code (optional)"
                  type="text"
                  value={refCode}
                  onChange={e => setRefCode(e.target.value)}
                />
              )}

              <ReceiveNews receiveNews={receiveNews} setReceiveNews={setReceiveNews} />

              {!showRefCode && (
                <div>
                  <span
                    className="od-login-page-right-form-fp"
                    onClick={() => setShowRefCode(true)}
                  >
                    Add Referral Code Here
                  </span>
                </div>
              )}
              <button className="od-login-page-right-form-sb" type="submit">
                {authLoading ? 'Loading...' : 'Complete signup'}
              </button>
            </form>
            <div className="text-center">
              Existing customer?{' '}
              <span
                className="od-login-page-right-form-fp"
                onClick={() => {
                  if (storeCode) {
                    history.push(
                      `/customer/account/login?whatsappId=${whatsappId}&mobileNo=${mobileNo}&storeCode=${storeCode}`
                    );
                  } else {
                    history.push('/customer/account/login');
                  }
                }}
              >
                Sign in
              </span>
            </div>

            <TermsAndConditionsText />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
