import React, { useState } from 'react';
import './ReceiveNews.scss';

const LIST_RECEIVE_NEWS = [
  { name: 'Email', isChecked: false, key: 'email' },
  { name: 'Text Message', isChecked: false, key: 'sms' },
];

const ReceiveNews = ({ receiveNews, setReceiveNews }) => {
  const [listReceiveNews, setListReceiveNews] = useState(LIST_RECEIVE_NEWS);

  const handleSetReceiveNews = (name, key) => {
    setReceiveNews({ ...receiveNews, [key]: !receiveNews[key] });
  };

  const toggleCheckboxChange = selectedItem => {
    setListReceiveNews(
      listReceiveNews.map(item =>
        item.name === selectedItem.name ? { ...item, isChecked: !item.isChecked } : item
      )
    );

    handleSetReceiveNews(selectedItem.name, selectedItem.key);
  };

  return (
    <div className="receive-news">
      <span>How would you like to receive news and special offers from us?</span>
      <ul className="receive-news__list">
        {listReceiveNews.map(item => (
          <li key={`receive-new-${item.name.toLowerCase()}`} className="receive-news__item">
            <input
              checked={item.isChecked}
              className="receive-news__item__checkbox"
              name={item.name}
              type="checkbox"
              value={item.name}
              onChange={() => toggleCheckboxChange(item)}
            />
            <label className="receive-news__item__name" htmlFor={item.name}>
              {item.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReceiveNews;
