import { odPushClevertapEvent } from '../utils/clevertap';
import { odPushGA4Event } from '../utils/gaFour';
import { odPushTTQEvent } from '../utils/tiktokPixel';

export const getSpecs = (allSpecs, dataLocale) => {
  const {
    MODEL_NO,
    FRAME_SHAPE,
    COLLECTION,
    FRAME_MATERIAL,
    NOSE_PAD,
    MATERIAL,
    FRAME_DIMENSIONS,
    FRAME_TYPE,
    GENDER,
  } = dataLocale;

  const allowedSpecNames = [
    MODEL_NO,
    FRAME_SHAPE,
    COLLECTION,
    GENDER,
    NOSE_PAD,
    MATERIAL,
    FRAME_DIMENSIONS,
    FRAME_TYPE,
    FRAME_MATERIAL,
  ];

  if (!Array.isArray(allSpecs) || allSpecs.length === 0) return [];

  const technicalSpecs = allSpecs?.filter(specs => specs.name === 'technical');
  const generalSpecs = allSpecs?.filter(specs => specs.name === 'general');

  const technicalSpecsItems = technicalSpecs[0]?.items;
  const generalSpecsItems = generalSpecs[0]?.items;
  const allSpecifications = [...technicalSpecsItems, ...generalSpecsItems];

  const allowedSpecs = allSpecifications.filter(spec => allowedSpecNames.includes(spec.name));

  return allowedSpecNames.flatMap(specName => {
    const spec = allowedSpecs.find(({ name }) => name === specName);

    return spec ? [spec] : [];
  });
};

export const getProductPrice = product => {
  const lenskartPrice = product?.prices[1]?.price;
  const firstBuyPrice = product?.prices?.length === 3 && product?.prices[2]?.price;
  const finalprice = product?.prices?.length === 3 ? firstBuyPrice : lenskartPrice;
  return finalprice;
};

export const getProductCurrency = product => {
  return product?.prices[0]?.currencyCode || product?.prices[0]?.currency_code;
};

export const getCurrencyCode = product => {
  if (!product || !Array.isArray(product?.prices) || product?.prices?.length === 0) {
    return null;
  }

  return product?.prices[0]?.currency_code;
};

export function determineCategory(pathname, cmsCategoryArray) {
  let category = 'accessories';
  category = category.replace('_', '-');
  if (cmsCategoryArray?.indexOf(category) < 0) {
    if (pathname?.indexOf('eyeglasses') > -1) {
      category = 'eyeglasses';
    }
    if (pathname?.indexOf('sunglasses') > -1) {
      category = 'sunglasses';
    }
    if (pathname?.indexOf('power-sunglasses') > -1) {
      category = 'power sunglasses';
      document.cookie = 'product_category=power_sunglasses';
    }
    if (pathname?.indexOf('premium-eyeglasses') > -1) {
      category = 'premium eyeglasses';
    }
    if (pathname?.indexOf('contact-lenses') > -1) {
      category = 'contact-lenses';
    }
  }
  if (category !== 'contact-lenses') {
    category = category.replace('-', ' ');
  }
  return category;
}

export function disableBackgroundScroll() {
  document.body.classList.add('overflow-hidden');
  document.body.classList.add('h-100cent');
}

export function enableBackgroundScroll() {
  document.body.classList.remove('overflow-hidden');
  document.body.classList.remove('h-100cent');
}

export const extractUtmSourceFromUrl = utmSourceStr => {
  const utmObj = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    utm_term: null,
  };
  if (utmSourceStr && utmSourceStr.split('?') && utmSourceStr.split('?')[1]) {
    const utmArr = utmSourceStr.split('?')[1].split('&');
    const utmTempObj = utmArr.reduce((filtered, str) => {
      if (str?.indexOf('utm_source') > -1) {
        filtered.utm_source = str.split('=')[1];
      } else if (str?.indexOf('utm_medium') > -1) {
        filtered.utm_medium = str.split('=')[1];
      } else if (str?.indexOf('utm_campaign') > -1) {
        filtered.utm_campaign = str.split('=')[1];
      } else if (str?.indexOf('utm_content') > -1) {
        filtered.utm_content = str.split('=')[1];
      } else if (str?.indexOf('utm_term') > -1) {
        filtered.utm_term = str.split('=')[1];
      }
      return filtered;
    }, {});

    return Object.assign({}, utmObj, utmTempObj);
  }
  return utmObj;
};

export function setDataAttr(gaObj) {
  const finalAttr = {};
  Object.entries(gaObj).forEach(([key, value]) => {
    const newKey = `data-${key}`;
    return Object.assign(finalAttr, { [newKey]: value });
  });
  return finalAttr;
}

export const replaceImageURL = (url, localeInfo, pid = 123) => {
  const pidList = [
    300918, 300917, 300916, 300915, 300914, 300913, 300912, 300911, 300910, 300909, 300908, 300907,
    300906, 300905, 300904, 300903, 300902, 300901, 300900, 300899, 300898, 300897, 300896, 300895,
    300894, 300893, 300892, 300891, 300890, 300889, 300888, 300887, 300881, 300880, 300879, 300878,
    300877, 300876, 300875, 300874, 300873, 300872, 300866, 300865, 300864, 300863, 300862, 300861,
    300860, 300859, 300432, 300431, 300430, 300429, 300428, 300427, 300426, 300425, 300424, 300423,
    300422, 300421,
  ];
  if (
    localeInfo?.domainUrl?.includes('preprod') &&
    url?.includes('/pro/') &&
    pidList.indexOf(parseInt(pid)) != -1
  ) {
    return url?.replaceAll('/pro/', '/pre/');
  }
  return url;
};

export const capitalizeLetters = (str, onlyFirst = false) => {
  if (str) {
    if (onlyFirst) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return str.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase());
  }
  return '';
};

/**
 * Formats a given date into the "dd/mm/yyyy" format.
 * @param {Date} date - The date to be formatted.
 * @returns {string} The formatted date string.
 */
export function formatDateToDDMMYYYY(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note that months are 0-based
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

/**
 * Calculates the optimal price based on the product data.
 *
 * @param {object} productData - The data for the product.
 * @returns {string} The formatted price.
 */
export const calculateOptimalPrice = productData => {
  const owndaysPrice = productData.prices[1].price;
  const firstBuyPrice = productData.prices.length === 3 && productData.prices[2].price;

  const finalprice = productData.prices.length === 3 ? firstBuyPrice : owndaysPrice;
  const priceNumber = parseFloat(finalprice);

  const formattedPrice = priceNumber.toFixed(2);
  return formattedPrice;
};

/**
 * Handles adding/removing a product to/from the wishlist.
 *
 * @param {Event} event - The event triggered by the user action.
 * @param {Object} product - The product object being manipulated.
 * @param {number} wishlistCount - The current count of items in the wishlist.
 * @param {Object} dataLocale - The locale data containing messages.
 * @param {string} login - The user's login information.
 * @param {function} dispatch - The Redux dispatch function.
 * @param {function} setToastMessage - Function to set toast messages.
 * @param {function} shortlist - Function to perform shortlisting actions.
 * @param {function} setSlMessage - Function to set shortlist messages.
 * @param {Object} shortListData - Data related to the shortlist.
 */
export const wishListHandlerHelper = (
  event,
  product,
  wishlistCount,
  dataLocale,
  login,
  dispatch,
  setToastMessage,
  shortlist,
  setSlMessage,
  shortListData,
  userInfo,
  categoryData,
  indexOfProduct
) => {
  const { SL_EXCEEDED_MESSAGE, SUCCESSFULLY_REMOVED, SUCCESSFULLY_ADDED } = dataLocale;
  event.preventDefault();
  event.stopPropagation();

  sessionStorage.setItem('hideshortlistinfo', true);

  const shortListProduct = shortListData?.result?.productIds?.concat(
    shortListData.result.productList.map(({ id }) => id)
  );

  const isShortlisted = shortListProduct?.indexOf(product.id) > -1;

  if (!isShortlisted && wishlistCount >= 50) {
    dispatch(setToastMessage({ message: SL_EXCEEDED_MESSAGE, timeout: 2000 }));
    return;
  }

  if (isShortlisted) {
    dispatch(shortlist(null, 'delete', `/${product.id}`));

    setSlMessage({ message: SUCCESSFULLY_REMOVED, timeout: 3000 });

    odPushClevertapEvent('Removed From Wishlist', {
      productID: product?.id,
      productName: product?.searchProductName,
      category: product?.classification,
      price: getProductPrice(product),
      currency: getProductCurrency(product),
      brand: product?.brand_name,
      variant: product?.model_name,
      productURL: product?.product_url,
      imageURL: product?.image_url,
    });
  } else {
    dispatch(shortlist(product.id, 'post', ''));

    setSlMessage({ message: SUCCESSFULLY_ADDED, timeout: 3000 });

    odPushClevertapEvent('Added To Wishlist', {
      productID: product?.id,
      productName: product?.searchProductName,
      category: product?.classification,
      price: getProductPrice(product),
      currency: getProductCurrency(product),
      brand: product?.brand_name,
      variant: product?.model_name,
      productURL: product?.product_url,
      imageURL: product?.image_url,
    });

    const gaPLPSelectItemData = JSON.parse(sessionStorage.getItem('ga-plp-select-item-data')) || {};

    odPushGA4Event('add_to_wishlist', {
      userInfo,
      gaPLPSelectItemData,
      categoryData,
      index: indexOfProduct,
    });

    odPushTTQEvent('AddToWishlist', {
      userInfo,
      categoryData,
      index: indexOfProduct,
    });
  }

  if (window.dtm.LenskartRewamp) {
    if (isShortlisted) {
      window.dtm.LenskartRewamp.Listing.click.removeProdcutShortlist(login, product.id.toString());
    } else {
      window.dtm.LenskartRewamp.Listing.click.shortlist(product.id.toString());
    }
  }
};

export const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const removeDomainName = url => {
  return url
    .replace('https://www.owndays.com/', '')
    .replace('https://prod.owndays.com/', '')
    .replace('https://www.lenskart.sg/', '')
    .replace('https://www.lenskart.com/', '')
    .replace('https://preproduction.owndays.com/', '')
    .replace('https://qe1.owndays.com/', '');
};

export const getFullAddress = (
  { addressline1, addressline2, city, state, postcode },
  localeInfo
) => {
  const stateFullAddress = `, ${state}`;
  const fullAddress = `${addressline1}, ${addressline2}, ${city}${state ? stateFullAddress : ''}${
    localeInfo.isPinCodeHide ? `, ${postcode}` : ''
  }`;
  return fullAddress;
};

/**
 * Parses specified query parameters from the URL.
 *
 * @param {Array<string>} paramsList - An array of parameter names to parse.
 * @returns {object} An object containing parsed query parameters.
 */
export const parseQueryParams = paramsList => {
  const queryParams = new URLSearchParams(window.location.search);
  const parsedParams = {};

  paramsList.forEach(param => {
    const paramValue = queryParams.get(param);
    if (paramValue !== null) {
      parsedParams[param] = paramValue;
    }
  });

  return parsedParams;
};

export const hideElementByIdUntilDate = (id, endDate, styles) => {
  const currentDate = new Date();
  const endDateTime = new Date(endDate);

  if (currentDate < endDateTime) {
    const elementToHide = document.getElementById(id);
    if (elementToHide) {
      const styleElement = document.createElement('style');
      styleElement.type = 'text/css';
      if (styleElement.styleSheet) {
        styleElement.styleSheet.cssText = styles;
      } else {
        styleElement.appendChild(document.createTextNode(styles));
      }
      document.head.appendChild(styleElement);

      elementToHide.setAttribute('data-hidden', 'true');
    }
  }
};

export function formatDeliveryDate(deliveryTimestamp) {
  if (deliveryTimestamp) {
    const deliveryDate = new Date(deliveryTimestamp);
    const day = deliveryDate.getDate();
    const monthIndex = deliveryDate.getMonth();
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = months[monthIndex];
    return `Expected delivery by ${day} ${month}`;
  }
  return '';
}

export const isHarryPotterProduct = id => {
  return [
    225427, 225435, 225428, 225436, 225425, 225429, 225431, 225432, 225433, 225434, 225426, 225430,
  ].includes(Number(id));
};
