import React, { Fragment } from 'react';

import { onImageLoadError } from '../../../utils/helper';

import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';

import './HeaderOwndaysProgressiveCP.scss';

export const HeaderOwndaysProgressiveCP = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'OWNDAYS PROGRESSIVE GLASSES' },
  ];

  return (
    <Fragment>
      <div className="od-header-owndays-progressive-cp__custom-breadcrumbs">
        <Breadcrumbs crumbs={breadcrumbs} />
      </div>

      <div className="od-header-owndays-progressive-cp">
        <img
          alt="OWNDAYS Free Enhanced Progressive Lenses"
          className="od-header-owndays-progressive-cp__container"
          src={`${baseImgUrl}/main-pc-08-2024.webp`}
          onError={e => onImageLoadError(e)}
        />

        <div className="od-header-owndays-progressive-cp__container__desc">
          <div className="od-header-owndays-progressive-cp__header">
            <span className="od-header-owndays-progressive-cp__header__main">
              PROGRESSIVE GLASSES
              <br />
              FROM <span className="od-header-owndays-progressive-cp__header--red">$198</span>
              {` ONLY`}
            </span>
          </div>

          <div className="od-header-owndays-progressive-cp__body">
            <h3>
              One pair of glasses to see far, intermediate {`and `}
              <br />
              reading zones
            </h3>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
