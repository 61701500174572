export const IMG_URL = 'https://static1.lenskart.com/media/owndays/img/contacts/clear-contactlens';
export const LINEUP = [
  {
    imgSrc: 'package-daily.webp',
    imgAlt: 'OWNDAYS CLEAR VISION DAILY',
    imgH: 92,
    tags: ['30 lenses per box', 'Daily'],
    title: 'OWNDAYS CLEAR VISION<br />DAILY',
    price: 'S$30',
    href: '/sg/en/contacts/clear-contactlens/daily',
  },
  {
    imgSrc: 'package-monthly.webp',
    imgAlt: 'OWNDAYS CLEAR VISION MONTHLY',
    imgH: 96,
    tags: ['6 lenses per box', 'Monthly'],
    title: 'OWNDAYS CLEAR VISION<br />MONTHLY',
    price: 'S$30',
    href: '/sg/en/contacts/clear-contactlens/monthly',
  },
  {
    imgSrc: 'package-daily-advance.webp',
    imgAlt: 'OWNDAYS CLEAR VISION ADVANCE DAILY',
    imgH: 92,
    tags: ['30 lenses per box', 'Daily'],
    title: 'OWNDAYS CLEAR VISION<br />ADVANCE DAILY',
    price: 'S$40',
    href: '/sg/en/contacts/clear-contactlens/daily-advance',
  },
  {
    imgSrc: 'package-monthly-advance.webp',
    imgAlt: 'OWNDAYS CLEAR VISION ADVANCE MONTHLY',
    imgH: 96,
    tags: ['6 lenses per box', 'Monthly'],
    title: 'OWNDAYS CLEAR VISION<br />ADVANCE MONTHLY',
    price: 'S$40',
    href: '/sg/en/contacts/clear-contactlens/monthly-advance',
  },
  {
    imgSrc: 'package-daily-blue.webp',
    imgAlt: 'OWNDAYS CLEAR VISION BLUE DAILY',
    imgH: 92,
    tags: ['30 lenses per box', 'Daily'],
    title: 'OWNDAYS CLEAR VISION<br /> BLUE DAILY',
    price: 'S$40',
    href: '/sg/en/contacts/clear-contactlens/daily-blue',
  },
];
