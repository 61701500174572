import React from 'react';
import Redirect from '../Redirect/Redirect';
import ClearSunglassesHTML from '../../own-days/html/ClearSunglassesHTML';

const TimerClearSunglasses = () => {
  const currentDate = new Date();
  const startDeactivate = new Date('2024-10-10T08:00:00Z');
  const endDeactivate = new Date('2024-10-10T09:00:00Z');
  const inStartEvent = currentDate >= startDeactivate;
  const inEndEvent = currentDate <= endDeactivate;

  if (inStartEvent && inEndEvent) {
    return <Redirect to="/news" />;
  } else {
    return <ClearSunglassesHTML />;
  }
};

export default TimerClearSunglasses;
