import React, { useState } from 'react';

import { onImageLoadError } from '../../../utils/helper';

import './FooterOwndaysProgressiveCP.scss';

export const FooterOwndaysProgressiveCP = ({ baseImgUrl }) => {
  const [isOpenTermAndConModal, setIsOpenTermAndConModal] = useState(false);

  const renderFreeEnhancedHeader = () => {
    return (
      <div className="od-footer-owndays-progressive-cp__container__free-enhanced-header">
        <h1 className="free-enhanced-header">
          {`FREE ENHANCED PROGRESSIVE `}
          <br />
          LENSES FOR NEW WEARERS
        </h1>
      </div>
    );
  };

  const renderFreeEnhancedBody = () => {
    return (
      <div className="od-footer-owndays-progressive-cp__container__free-enhanced-body-box">
        <div className="od-footer-owndays-progressive-cp__container__free-enhanced-body">
          <div className="free-enhanced-body__desc-box">
            <p>
              If you have been struggling to see clearly at different distances or dealing with the
              hassle of having to switch between multiple pairs of glasses, there’s no better time
              to try out progressive lenses than now!
            </p>

            <p>
              From 01 - 31 August, enjoy FREE Enhanced Progressive Lenses at OWNDAYS with any
              purchase of spectacles. This limited-time offer is exclusive to customers who have not
              purchased any progressive lenses from OWNDAYS before and is valid at all stores except
              the premium concept stores at MBS and Takashimaya S.C.
            </p>

            <div className="free-enhanced-body__remark-box">
              <span className="free-enhanced-body__remark-box__text">
                * 2-week processing time is required
              </span>
              <div
                className="free-enhanced-body__remark-box__link-open-modal"
                onClick={() => setIsOpenTermAndConModal(true)}
              >
                <span className="free-enhanced-body__remark-box__text">* T&Cs apply</span>
                <img
                  alt=""
                  src={`${baseImgUrl}/icon-external-link.webp`}
                  onError={e => onImageLoadError(e)}
                />
              </div>
            </div>
          </div>

          <img
            alt="FREE ENHANCED PROGRESSIVE LENSES FOR NEW WEARERS"
            className="free-enhanced-body__img"
            src={`${baseImgUrl}/img-2.webp`}
            onError={e => onImageLoadError(e)}
          />
        </div>
      </div>
    );
  };

  const renderBookEyeTestButton = () => {
    return (
      <div className="od-footer-owndays-progressive-cp__container__book-online-btn">
        <a className="book-online-btn" href="/stores/sg" target="_self">
          <span>BOOK AN EYE TEST ONLINE</span>

          <img
            alt="book-an-eye-test-online"
            className=""
            src={`${baseImgUrl}/arrow-right-circle-icon.svg`}
            onError={e => onImageLoadError(e)}
          />
        </a>
      </div>
    );
  };

  const renderTermAndConModal = () => {
    return (
      isOpenTermAndConModal && (
        <div className="od-footer-owndays-progressive-cp__container__term-and-con-modal-box">
          <div className="od-footer-owndays-progressive-cp__container__term-and-con-modal">
            <div className="term-and-con-modal">
              <img
                alt=""
                className="term-and-con-modal__close-icon"
                src={`${baseImgUrl}/close-icon.svg`}
                onClick={() => setIsOpenTermAndConModal(false)}
                onError={e => onImageLoadError(e)}
              />
              <h2>Terms & Conditions</h2>
              <ul>
                <li>
                  Offer is valid for customers who have not purchased any progressive lens upgrades
                  from OWNDAYS previously and is subject to the customers’ suitability for
                  progressive lens wear and lens stock availability
                </li>
                <li>
                  Offer is valid for the purchase of one pair of prescription glasses per eligible
                  customer only and excludes the lens replacement service
                </li>
                <li>
                  $100 discount will be applied for upgrades to Prestige or Prestige Gold
                  progressive lenses only
                </li>
                <li>Regular top up fees apply for other additional lens options</li>
                <li>
                  The standard warranty coverage of one-time complimentary change of lens degree is
                  not applicable for the free upgrade to Enhanced progressive lenses
                </li>
                <li>
                  Offer is valid at all OWNDAYS retail shops in Singapore, except the premium
                  concept stores at Takashimaya S.C. and MBS
                </li>
                <li>A two-week processing time is required during the campaign period</li>
                <li>
                  Offer cannot be used in conjunction with other discounts, vouchers and/or
                  promotions
                </li>
                <li>
                  The management reserves the right to amend the offer and the terms and conditions
                  without prior notice
                </li>
              </ul>
            </div>
          </div>
        </div>
      )
    );
  };

  return <div className="od-footer-owndays-progressive-cp">{renderBookEyeTestButton()}</div>;
};
